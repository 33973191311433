import type { DeviceModel } from "@laast-io/types";

export function hydrateDeviceModelRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as DeviceModel['id'] | null | undefined
  }

  return hydrateDeviceModel(input)
}
export function hydrateDeviceModel(
  input: any
) {
  return {
    ...(input as DeviceModel),
  }
}